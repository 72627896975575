<script lang="ts">
	import userStore, { UserRoles } from 'src/stores/user';
	import { fade, fly } from 'svelte/transition';
	import Spinner from './common/spinner.svelte';

	let menuOpen = false;
	const userPromise = userStore.getUser();
</script>

<header class="flex flex-row items-center justify-between">
	<div class="w-[200px]">
		<a href="/">
			<img src="/img/index/header/miditec_long.png" alt="" />
		</a>
	</div>
	{#await userPromise}
		<Spinner size="4rem" color="neutral-500" />
	{:then user}
		{#if user}
			<div class="flex flex-row space-x-2 items-center">
				<img
					class="rounded-full w-[38px] h-[38px] object-square-crop-top"
					src={user.profile_picture?.path || '/img/user/no-avatar.svg'}
					alt=""
				/>
				<h4 class="hidden md:block text-2xl">Olá,</h4>
				<h4 class="hidden md:block text-2xl text-think-500">{user.names[0]?.name || ''}</h4>
			</div>
		{/if}
	{/await}
	<div class=" flex flex-row items-center space-x-6">
		<!-- <div>
			<img src="/img/index/header/notification.svg" alt="" />
		</div> -->
		<div class="dropdown flex flex-col items-end z-20 text-gray-brand-500">
			<button on:click={() => (menuOpen = !menuOpen)} {menuOpen} class="hover:brightness-75">
				<img src="/img/index/header/menu.svg" alt="" />
			</button>
			{#if menuOpen}
				<!-- content here -->
				<div
					class="dropdown-content flex-col space-y-4 mt-10 p-4 rounded-2xl"
					transition:fly|local={{ x: 0, y: -50 }}
				>
					<a
						href="https://relato-de-problemas.paperform.co/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div class="flex items-center space-x-3">
							<div class="bg-white hover:text-gray-700">
								<img src="/img/index/header/icon_help.svg" alt="" />
							</div>
							<p>Ajuda</p>
						</div>
					</a>
					<a href="/profile" class="flex items-center space-x-3">
						<div>
							<img src="/img/index/header/icon_edit.svg" alt="" />
						</div>

						<p>Editar Perfil/Projeto</p>
					</a>
					<a href="/teams" class="flex items-center space-x-3">
						<div class="text-gray-brand-500">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								stroke-width="2"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
								/>
							</svg>
						</div>

						<p>Trocar de Projeto</p>
					</a>
					{#await userPromise then user}
						{#if user.role === UserRoles.ADMIN || user.role === UserRoles.MANAGER}
							<a href="/manager" class="flex items-center space-x-3">
								<div class="text-gray-brand-500">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										class="w-6 h-6"
									>
										<path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
										<path
											fill-rule="evenodd"
											d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
											clip-rule="evenodd"
										/>
									</svg>
								</div>

								<p>Dashboard do Gestor</p>
							</a>
						{/if}
					{/await}
					<button class="flex items-center space-x-3" on:click={userStore.logout}>
						<div>
							<img src="/img/index/header/icon_disconect.svg" alt="" />
						</div>
						<p class="text-red-600">Desconectar</p>
					</button>
				</div>
			{/if}
		</div>
		<a href="https://chat.txm.business/" target="_blank">
			<button class="hidden md:block button text-think-500 w-28"> Chat </button>
		</a>
	</div>
</header>

{#if menuOpen}
	<div
		class="top-0 left-0 fixed w-screen h-screen z-10 bg-neutral-100 opacity-30"
		on:click={() => {
			menuOpen = false;
		}}
		transition:fade|local
	/>
{/if}

<style>
	.dropdown-content {
		position: absolute;
		background-color: #fff;
		min-width: 250px;

		z-index: 1;

		box-shadow: -3px -4px 4px rgba(255, 255, 255, 0.25), 2px 5px 9px rgba(0, 0, 0, 0.2);
	}
	.dropdown {
		position: relative;
	}
</style>
